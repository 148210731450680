
const INITIAL_STATE = {
  signedIn: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SIGNIN":
      return {
        ...state,
        signedIn: true
      };
    case "SIGNOUT":
      return {
        ...state,
        signedIn: false
      };
    default:
      return state;
  }
};

export default userReducer;
