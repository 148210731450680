import React from 'react';
import { HashLink, } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";

import Logo from '../../assets/images/DANASH.png';


function NavbarItems({scrolled, project, width}) {
    let location = useLocation()
    let path = location.pathname
    let b = scrolled || project  ? 'NavbarItems_Scrolled NavbarItems' : 'NavbarItems'
    return(
        <div  className={b}   >
            { width < 600  &&
                <HashLink to="/#header">
                    <img 
                        alt='Reboost Logo' 
                        src={Logo} 
                        className={'Navbar_logo'}
                    />
                </HashLink>
            }
            
            <HashLink
                to="/#header"  
                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                style={
                    project 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : path === '/' && scrolled 
                    ? {color:"#294B81"} 
                    : scrolled 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : {color: "white"}
                }
            >
                Home
            </HashLink>
            <HashLink 
                to="/aboutus" 
                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                style={
                    project 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : path === '/aboutus' && scrolled 
                    ? {color:"#294B81"} 
                    : scrolled 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : {color: "white"}
                }
            >
                About Us
            </HashLink>
            <HashLink 
                to="/projects" 
                style={
                    project 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : path === '/projects' && scrolled 
                    ? {color:"#294B81"} 
                    : scrolled 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : {color: "white"}
                }
            >
                Projects 
            </HashLink>
            <HashLink 
                to="/contact" 
                style={
                    project 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : path === '/contact' && scrolled 
                    ? {color:"#294B81"} 
                    : scrolled 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : {color: "white"}
                }
            >
                Contact Us 
            </HashLink>
            <HashLink 
                to="/dashboard" 
                style={
                    project 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : path === '/dashboard' && scrolled 
                    ? {color:"#294B81"} 
                    : scrolled 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : {color: "white"}
                }
            >
               Dashboard
            </HashLink>
            <a 
                href='/Danash-brochure.pdf' 
                download 
                style={
                    project  || scrolled 
                    ? {color: "rgba(0,0,0,.6)"} 
                    : {color: "white"}
                }
            >
                Brochure
            </a>
        </div>
    );
}

export default NavbarItems