import React from 'react'
import Upload from '../../assets/images/upload.png';

import TipEditor from '../../components/TipEditor/TipEditor'

const AboutUsSection = ({editableSection, aboutUsText, setBodyHtml, saveOneImage, onSaveClick, onAboutUSTextChange, imageSrc2}) => {
  
  
    return (
        <div className='EditHeader aboutus'>
            <h1>Edit {editableSection} Section</h1>
            <div className="InfoInputs">
                <div  className="subContainer_Info">
                    <div>
                    <h3>Title</h3>
                    <input type="text" value={aboutUsText.title}   onChange={e => onAboutUSTextChange(e, "title")}/>
                    </div>
                    <div>
                    <h3>Body</h3>
                    <TipEditor body={aboutUsText.text} setBodyHtml={setBodyHtml}/>
                    </div>
                </div>
            </div>
            <h3 className='h3'>Image:</h3>
            <img alt="" src={imageSrc2(aboutUsText.image)} className="dreamImage"/>
            <div  className='dreamInputImage'>
                <img alt='' src={Upload} className='upload'/>
                <label>Upload Image</label>
                <input type="file" onChange={(e)=> saveOneImage(e)} className='inputfile'/>
            </div>
            <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
        </div>
    )
}

export default AboutUsSection