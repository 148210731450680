import React, { useState, useEffect } from 'react';
import DropDown from './DropDown';

export default function ClientsSection({ backendUrl, clients}) {

    const [data, setData] = useState({})
    useEffect(() => {
        setData(JSON.parse(clients.text))
    }, [clients])

    return (
        <div className='clients_container' id='clients'>
            <h2>{data.title}</h2>
            <p>{data.description}</p>
            {
                data.clients &&
                Object.keys(data.clients).map((keyName, i) => 
                    <div key={i}>
                        <DropDown 
                            show={true} 
                            type={data.clients[keyName].title} 
                            backendUrl={backendUrl} 
                            images={data.clients[keyName].clients}  
                            key={i} />
                    </div>
                )
            }
        </div>
    )
}


