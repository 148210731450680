import React, { useEffect, useState, useContext } from 'react';

import './AboutUs.styles.scss';
import { HashLink } from 'react-router-hash-link';
import { SigninContext } from '../../SigninContext';

function AboutUs({aboutus}) {
  const {backendUrl}= useContext(SigninContext)
  const [fetchedImages, setFetchedImages] = useState(false)
  const [aboutUsText, setAboutUsText] = useState({})
  const imageSrc = (name) => {
    return `${backendUrl}${name}`
  }

  useEffect(() => {
    setAboutUsText(JSON.parse(aboutus.text))
    console.log("image about us",(aboutus.images.image.image) )
    setFetchedImages(true)
  }, [aboutus])



  return fetchedImages&&(
    <div className='AboutUs_Container'>
      <div className='row1'>
        <img alt='' src={imageSrc(aboutus.images.image.image)} className='img' />
        <div className='background' style={{display: "none"}}/>
      </div>
      <div className='description'>
        <h2>{aboutUsText.title}</h2>
        <div dangerouslySetInnerHTML={{__html: aboutUsText.text}} />
        <HashLink to='/aboutus' className='Primary_Button btn-0' >
          {aboutUsText.button}
        </HashLink>
      </div>
    </div>
  )
}

export default AboutUs