import React, { useEffect, useState, useContext } from 'react';
import Aos from 'aos';
import axios from 'axios';

import './RoadMap.style.scss';
import RoadMapCard from '../RoadMapCard/RoadMapCard';
import { SigninContext } from '../../SigninContext';

function RoadMap({buss}) {
  const {backendUrl}= useContext(SigninContext)
  const [roadMapItems, setRoadMapItems] = useState([])

  useEffect(()=>{
    Aos.init({ duration: 500 })
  }, [])

  useEffect(() => {
    setRoadMapItems(JSON.parse(buss.text))
  }, [buss])

  return (
    <div className='RoadMap_Container' id='business verticals'>
      <h2>Business Verticals</h2>
      <div className='cards_container'>
        {
           roadMapItems.map((item, i) => 
            <RoadMapCard gray={2}  text={item.text} image={item.image} key={i} i={i} backendUrl={backendUrl}/>
          )
        }
      </div>
       
    </div>
  )
}

export default RoadMap