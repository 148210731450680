import React, { useEffect, useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';

import './OurProjects.styles.scss';
import { projects } from '../../assets/data';
import ProjectCard from '../ProjectCard/ProjectCard'
import AnimatedDescription from '../AnimatedDescription/AnimatedDescription'
import { SigninContext } from '../../SigninContext';


function OurProjects({keyProjects}) {

  const [keyprojects, setKeyProjects] = useState([])
  const [fetchedKey, setFetchedKey] = useState(false)
  const { backendUrl}= useContext(SigninContext)
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [keyProjectsItems, setKeyProjectsItems] = useState({})

  useEffect(() => {
    axios.get(`${backendUrl}keyprojects`).then(res =>
      {
        setKeyProjects(res.data)
        setFetchedKey(true)
      }
    )
  }, [keyProjects])
  useEffect(() => {
    setKeyProjectsItems(JSON.parse(keyProjects.text));
    setFetchedProjects(true)
  }, [keyProjects])               
  return fetchedProjects && (
    <div className='OurPorjects_Container'>
        <AnimatedDescription  title={keyProjectsItems.title} description={keyProjectsItems.text} />
      <div className='projects'>
        {
          fetchedKey && keyprojects.length > 0 && keyprojects.map((project, i) =>{ 
            let images = project.image
            let type = project.type
            let title = project.title
            let pathname = project.pathname
            return  (
              <ProjectCard 
                images={images} 
                key={i}
                pathname={pathname}
                title={title}
                id={project.id}
                type={type}
                date={project.startdate}
              />
            )
          })
        }
      </div>
      <HashLink to="projects" className='Primary_Button btn-0 '>
        View All Projects
      </HashLink>
       
    </div>
  )
}

export default OurProjects