import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";

import ProjectCard from '../ProjectCard/ProjectCard';
import './ProjectSection.style.scss'
import AnimatedDescription from '../AnimatedDescription/AnimatedDescription';
import { SigninContext } from '../../SigninContext';

function ProjectSection() {

  const [projects, setProjects] = useState([])
  const [newProjects, setNewProjects] = useState([])
  const [fetched, setFetched] = useState(false)
  const [page, setPage] = useState(0)
  const [length, setLength] = useState()
  const [hasMore, setHasMore] = useState(true)

  const {backendUrl}= useContext(SigninContext)
  

  useEffect(() => {
    console.log("Start")
      axios.get(`${backendUrl}lazyloading/${page}`).then(res =>
      {
        setProjects([ ...projects, ...res.data.data])
        setLength(res.data.length)
      }
    )
  }, [page])

  useEffect(()=> {
    console.log("projects length",projects.length)

  }, [projects])
  const Next = () => {
    if (projects.length >= length) {
      setHasMore({ hasMore: false })
      return;
    }
    setPage(page + 1)
  }

  const [projectFilter, setProjectFilter] = useState("All")
  const [stopSpinning, setStopSpinning] = useState(false)

  useEffect(() => {  
    const interval = setInterval(() => { 
      setStopSpinning(true)} , 1000); return () => clearInterval(interval);
    }, [projectFilter]
  );

  const handleChange = (e) => {
    setProjectFilter(e.target.value)
    setStopSpinning(false)
  }

  return (
    <div className='ProjectSection_Container' id='team'
    >
      <div className='row1'>
        <AnimatedDescription title="Our Projects" description="Since its foundation, DANASH has executed a large number of EPC projects. Here is an overview of the company’s executed projects."/>
        <select value={projectFilter} onChange={handleChange}  >
          <option value="All">All Projects</option>
          <option value="Roads">Roads Projects</option>
          <option value="Buildings">Buildings Projects</option>
          <option value="Infrastructure">Infrastructure</option>
          <option value="Marine">Marine</option>
          <option value="Lakes">Lakes</option>
          <option value="Operation and Maintenance">Operation and Maintenance</option>
        </select>
      </div>

      {
        stopSpinning  ? 
          <InfiniteScroll
          dataLength={projects.length}
          next={Next}
          hasMore={hasMore}
        >
        <div className='Projects_Container'>

          {
            projects.map((project, i) =>{ 
              let images = project.image.length > 0 ? project.image : null
              let type = project.type
              let title = project.title
              let pathname = project.pathname
              return type === projectFilter||  projectFilter === "All" ? (
                <ProjectCard 
                  images={images} 
                  key={i}
                  pathname={pathname}
                  title={title}
                  id={project.id}
                  type={type}
                  date={project.startdate}
                />
              )
              : null
            })
       
          }
        </div>

          </InfiniteScroll>
        :  <div className='spinner'/>
      }
     
    </div>
  )
   
}

export default ProjectSection