import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';

import './EditProject.styles.scss';
import deleteIcon from '../../assets/images/deleteMini.svg'
import { SigninContext } from '../../SigninContext';
import ProjectPage from '../../pages/ProjectPage/ProjectPage';
import TipEditor from '../TipEditor/TipEditor';

function EditProject({imageSrc, setUpdating, updating,length, onProjectDelete, onCancelEditClick, showAddProject, ids, setShowEdit, showEdit, setShowAddProject}) {
    
    const [uploadedImage, setUploadedImage] = useState([])
    const [newImages, setNewImages] = useState([])
    const {backendUrl, setFetched, fetched}= useContext(SigninContext)
    const [fetchedProject, setFetchedProject] = useState(false)
    const [bodyHtml, setBodyHtml] = useState()
    const [projectImages, setProjectImages] = useState([])
    const [spin, setSpin] = useState(false)

    
    const [projects, setProjects] = useState({
        id: 0,
        title:"",
        client:"",
        description:"",
        startdate:"",
        enddate:"",
        keyproject: false,
        image:[],
        type: "Roads"
    })

    useEffect(() => {
        setProjects(projects => ({
            ...projects,
            description: bodyHtml
        }))
    }, [bodyHtml])

    useEffect(() => {
        if(!showAddProject){
        axios.get(`${backendUrl}project/${ids}`).then(res =>
            {
                setProjects(res.data[0])
                setNewImages(res.data[0].image)
                setProjectImages(res.data[0].image)
                setFetchedProject(true)
            }
        ).catch(err => console.log("errorrr heres", err))
        } else{
            setFetchedProject(true)
        }
    }, [fetched, updating, spin, showAddProject])

    const onInputsChange = (e, item) => {
        setProjects( projects => ({
            ...projects,
            [item]: e.target.value
        }))
    }
  
    const RemoveImage = (i) => {
        let NewArray0 = projects.image.filter(function(value, index, arr){ 
            return value !== projects.image[i];
        });
        setProjects( projects => ({
            ...projects,
            image: NewArray0
        }))
        setNewImages(NewArray0)
        let NewArray = projectImages.filter(function(value, index, arr){ 
            return value !== projectImages[i];
        });
        setProjectImages(NewArray)
        // axios.put(`${backendUrl}removeImage`,{
        //     image: JSON.stringify(NewArray),
        //     deletedImage: projects.image[i],
        //     id: project.id,
        //     pathname: projects.pathname
        // }
        // ).then(res =>{
        //     console.log(res)
        // }).catch(err => console.log(err))
    }
    const RemoveUploadedImage = (i) => {
        let NewArray = uploadedImage.filter(function(value, index, arr){ 
            return value !== uploadedImage[i];
        });
        let NewArray2 = projectImages.filter(function(value, index, arr){ 
            return value !== uploadedImage[i].name;
        });
        setUploadedImage(NewArray)
        setProjectImages(NewArray2)
    }

    useEffect(() => {
        if(spin){
            const interval = setInterval(() => { 
            setSpin(false)} , 1000); return () => clearInterval(interval); 
        }    
    }, [spin]);
  
   
    const onSaveChanges = () => {
        setSpin(true)
        const formData = new FormData();
        formData.append("title", projects.title);
        formData.append("client", projects.client);
        formData.append("description", projects.description);
        formData.append("enddate", projects.enddate);
        formData.append("startdate", projects.startdate);
        formData.append("type", projects.type);
        formData.append("keyproject", projects.keyproject);
        formData.append("image", JSON.stringify(projectImages));
        console.log("projectImages", projectImages)
        uploadedImage.length > 0 && uploadedImage.map( image =>  {
            formData.append(image.name, image)
        })

        if(showAddProject){
            if(projects.type.length < 1){
                return alert("Please select project type")
            }
            let newpathname = "p" + (length + 1) + projects.type[0]
            formData.append("pathname", newpathname);
            axios.post(`${backendUrl}addProject`,
                formData
            ).then(res =>{
                setUpdating(!updating)
                alert("Project added, thank you!")
                onCancelEditClick()
            }).catch(err => {
                if (err.response.data.detail.slice(-7) === "exists."){
                    alert("Please pick another title, title already exists")
                }else {
                    alert("Error adding project, please try again")
                    console.log("err", err)
                }
            })
        }else{
            formData.append("pathname", projects.pathname);
            formData.append("id", projects.id);
            axios.put(`${backendUrl}updateProject`,
                formData
            ).then(res =>{
                setUpdating(!updating)
                setSpin(true)
            }).catch(err => {
                if (err.response.data.detail.slice(-7) === "exists."){
                    alert("Please pick another title, title already exists")
                }else {
                    alert("Error adding project, please try again")
                }
            })
        }
        setTimeout(() =>{
            setFetched(!fetched)
            setUpdating(!updating)
            setUploadedImage([])
        }, [100])
    }
    
    const saveFile = (e) => {
        let imgs = uploadedImage
        let array = [...projects.image]
        let files = e.target.files
        Object.keys(files).map(keyName => {
            imgs.push(files[keyName]);
            array.push(files[keyName].name);
        })
        setProjectImages(array)
        setUploadedImage(imgs);
    }

    const DisplayProject = useCallback(() => {
        return projects && <ProjectPage id={true} editProject={projects} />
    }, [projects, fetchedProject, fetched])

    const UploadedImagesRender = useCallback(() => {
        return uploadedImage.length > 0 && (
            <div>
                 <label>Uploaded Images</label>
                    <div className='images_container'>
                        <div className="images"> 
                            {uploadedImage.map((image, i) => 
                                <div key={i}> 
                                    <div  
                                        className="image"  
                                    >
                                        <img alt="" src={URL.createObjectURL(image)}  className="renderedImage"/>
                                        <img alt="" src={deleteIcon} onClick={() => RemoveUploadedImage(i)} className="deleteIcon"/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
            </div>
        )
    }, [uploadedImage, projectImages])

    return  spin ? <div className='spinner spinner2' />
    
    : fetchedProject && (
        <div className='EditProject_Container'>
            <form  className="EditProject_Container_Form" >
                <h2>Project Update</h2>
                <div className='inputs'>
                    <label>Title: </label>
                    <textarea value={projects.title} type="text" required onChange={(e) => onInputsChange(e, "title")} className="textarea1"/>
                </div>
                <div className='inputs'>
                    <label>Client:</label>
                    <input value={projects.client} type="text" required onChange={(e) => onInputsChange(e, "client")} />
                </div> 
                <div  className='inputs'>
                    <label>Description:</label>
                    <TipEditor body={projects.description} setBodyHtml={setBodyHtml}/>
                </div>
                <div  className='inputs'>
                    <label>Start Date:</label>
                    <input value={projects.startdate} type="text" required onChange={(e) => onInputsChange(e, "startdate")} />
                </div>
                <div  className='inputs'>
                    <label>End Date:</label>
                    <input value={projects.enddate} type="text" required onChange={(e) => onInputsChange(e, "enddate")} />
                </div>
                <div  className='inputs'>
                    <label>Type</label>
                    {
                        showAddProject 
                        ? <select value={projects.type} onChange={(e) => onInputsChange(e, "type")} className="select" required>
                            <option defaultValue  hidden> Select Type</option>
                            <option value="Roads">Roads Projects</option>
                            <option value="Buildings">Buildings Projects</option>
                            <option value="Infrastructure">Infrastructure</option>
                            <option value="Marine">Marine</option>
                            <option value="Lakes">Lakes</option>
                            <option value="Operation and Maintenance">Operation and Maintenance</option>
                        </select>
                        : <input value={projects.type} type="text" required onChange={(e) => onInputsChange(e, "type")} />
                    }
                </div>
                <div  className='inputs'>
                    <label>Key Project</label>
                    <select value={projects.keyproject} onChange={(e) => onInputsChange(e, "keyproject")}>
                        <option>False</option>
                        <option>True</option>
                    </select>
                </div>
                <div className='images_container'>
                    {newImages.length > 0 &&  <label>Images</label>}
                    <div className="images"> 
                        {newImages.length > 0 && newImages.map((image, i) => 
                            <div key={i}> 
                                <div  
                                    className="image"  
                                >
                                    <img alt="" src={imageSrc(image, projects.pathname, projects.type)}  className="renderedImage"/>
                                    <img alt="" src={deleteIcon} onClick={() => RemoveImage(i)} className="deleteIcon"/>
                                </div>
                                <p>{image}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div  className='inputs'>
                    <UploadedImagesRender />        
                    <input type="file" onChange={(e)=> saveFile(e)} multiple className='file'/>
                </div>
                <div className='flex'>
                    <button onClick={() => onSaveChanges()} className='btn-3 Green_Button'>
                        {showAddProject ? "Add Project" : "Save Changes"}
                    </button>
                    { !showAddProject &&
                        <button onClick={() => onProjectDelete(projects.id)} className='btn-2 Delete_Button'>
                            Delete Project
                        </button>
                    }
                    <button onClick={() => onCancelEditClick()} className='btn-1 Tertiary_Button'>
                        Go Back
                    </button>
                </div>
            </form>
            <DisplayProject />
        </div>
    )
}

export default EditProject