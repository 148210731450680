import React, { useState, useEffect } from 'react';
import axios from 'axios';


export default function AboutusSection({imageSrc, aboutus}) {

    const [data, setData] = useState({})
    useEffect(() => {
      {
        setData(JSON.parse(aboutus.text))
      }
    }, [aboutus])

  
  return (
    <div className='row1' id='about'>
        <img alt='' src={imageSrc(data.image)} className='img' />
        <div className='description reduced'>
            <h2>{data.title}</h2>
            <div dangerouslySetInnerHTML={{__html: data.text}} />
        </div>
    </div>
  )
}
