import React, { useState, useEffect } from 'react';
import axios from 'axios';


export default function MissionSection({ imageSrc, mission}) {

  const [data, setData] = useState({})
  useEffect(() => {
    {
      setData(JSON.parse(mission.text))
    }
  }, [mission])

  return (
    <div 
      className='description2' 
      id='mission'
      style={{
          backgroundImage: `linear-gradient(rgba(41, 105, 167, .3),rgba(41, 105, 167, .5)) , url(${imageSrc(data.image)})`, 
      }}
    >
      <h2>{data.title}</h2>
      <p>{data.text}</p>
    </div>
  )
}


