import React, { useEffect } from 'react';

import './RoadMapCard.style.scss';


function RoadMapCard({image, text, backendUrl}) {
  const imageSrc = (name) => {
    return `${backendUrl}${name}`
  }
  return (
    <div 
      className='RoadMapCard_Container' 
      >
       { image &&  <img alt='' src={imageSrc(image)} style={{width: "100px"}}/>}
          <p>{text}</p>
    </div>
  )
}

export default RoadMapCard