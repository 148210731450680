import React, { useState, useContext } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { signIn } from '../../redux/user/user.actions'

import './SigninForm.styles.scss'
import { SigninContext } from '../../SigninContext';

function SigninForm({signIn}) {
    const { backendUrl }= useContext(SigninContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const handleSubmit = () => {
        axios.post(`${backendUrl}signin`, {
          email: email,
          password: password
        })
        .then(function (response) {
          if(response.data === 'success'){
              signIn()
              console.log("success")
          } else{
            alert("Wrong email or password!")
          }
        })
        .catch(function (error) {
          alert("Wrong user name or password, please try")
        })
    }
    return (
        <form className='form'>
            <h2>Sign In</h2>
            <div className='input'>
                <label>Email</label>
                <input type="text" value={email} onChange={(e) =>setEmail(e.target.value)}/>
            </div>
            <div className='input'>
                <label>password</label>
                <input type="password" value={password} onChange={(e) =>setPassword(e.target.value)}/>
            </div>
            <button className='btn-1 Tertiary_Button' onClick={() => {handleSubmit()}}>Sign In</button>
        </form>
    )
}

const mapDispatchToProps = (dispatch) => ({
  signIn: () => dispatch(signIn()),
});

export default connect(null, mapDispatchToProps)(SigninForm)
