import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import { SigninContext } from '../../SigninContext';

import './ProjectPage.styles.scss';

import Right from '../../assets/images/right.svg'
import Left from '../../assets/images/left.svg'
import building from '../../assets/images/building.jpg';
import infrastructure from '../../assets/images/infrastructure.jpg';
import road from '../../assets/images/road.jpg';
import marine from '../../assets/images/marine.jpg';
import lake from '../../assets/images/lake.jpg';
import maintenance from '../../assets/images/maintenance.jpg';

function ProjectPage({id, editProject}) {
  const [index, setIndex] = useState(0)
  const [project, setProject] = useState([])
  const [hasImage, setHasImage] = useState(false)
  const { backendUrl, fetched }= useContext(SigninContext)

  let parm = useParams()

  let j = id ? id :  parm.projectid
  

  useEffect(() => {
    if(id){
      setProject(editProject)
      if(editProject.image.length){
        setHasImage(true)
      }
    } else{
      axios.get(`${backendUrl}project/${j}`).then(res =>
        {
          setProject(res.data[0])
          if(res.data[0].image.length){
            setHasImage(true)
          }
        }
      ).catch(err => console.log("errorrr here", err))
    }
     
  }, [j, fetched, editProject])



  const {startdate, enddate, role, client, type, title, description, image, pathname} = project


  let imageArray = hasImage ?
    image
    : type === 'Buildings' 
    ? [building] 
    : type === 'Infrastructure' 
    ? [infrastructure] 
    : type === 'Roads'
    ? [road]
    : type === 'Marine'
    ? [marine]
    : type === 'Lakes'
    ? [lake]
    : [maintenance]

  const imageSrc = (name, path, type) => {
    let slicedPath = path.slice(0, -1)
    let symbol = type[0]
    let newPath = slicedPath + symbol
    return `${backendUrl}${newPath}/${name}`
  }

  let selectedImage = hasImage ? imageSrc(image[index], pathname, type) : imageArray[0]
  const handleImageClick = (i) => {
    setIndex(i)
  }
  const ref = useRef(null)
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const alternateImages = () => {
    if (index === imageArray.length -1) {
      setIndex(0)
    }
    else setIndex( index + 1)

  }
 
  useEffect(() => {  
    const interval = setInterval(() => { 
      alternateImages()} , 3000);
      return () => clearInterval(interval);
    }, [index]
  );

  return (
    <div className='ProjectPage_Container' >
      { imageArray && 
        <div className='Images_container'>
          <img alt="" src={selectedImage} className="first_image"/>
          <div className='rendered_images'> 
            { imageArray.length > 5 && <button  onClick={() => scroll(-110)}> <img alt="" src={Left} className="arrow" /></button> }
            <div className='Images ' ref={ref}>
              {
                imageArray && imageArray.length > 1 && imageArray.map((image, i) =>
                <div onClick={() => handleImageClick(i)} key={i}>
                  <img 
                    alt="" 
                    src={imageSrc(image, pathname, type)} 
                    id={`${i}`} 
                    className="image" 
                    style={index === i ? {transform: "scale(1.1)"} :  {opacity: .7}}
                  />
                </div>
                )
              }
            </div> 
            { imageArray.length > 5 && <button  onClick={() => scroll(110)}> <img alt="" src={Right} className="arrow" /></button> }
         </div>
        </div>
      }
      <div className='description'>
        <h1>{title}</h1>
        <p className='type'><span>Type: </span>{type} </p>
        <p><span>Date: </span><span className='date'>{startdate} {">"} {enddate}</span></p>
        <p><span> Client:</span> {client}</p>
        <p><span> Role:</span> {role}</p>
        { description !== `<p dir="auto"></p>` && <div><span className='p'>Description:</span>  <div dangerouslySetInnerHTML={{__html: description}} /> </div>}
      </div>
   </div>
  )
}


export default ProjectPage
