import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { setHomeData } from '../../redux/pages/pages.actions';
import './HomePage.style.scss';
import Header from '../../components/Header/Header';
import Info from '../../components/Info/Info';
import AboutUs from '../../components/AboutUs/AboutUs';
import Dream from '../../components/Dream/Dream';
import OurProjects from '../../components/OurProjects/OurProjects';
import RoadMap from '../../components/RoadMap/RoadMap';
import { SigninContext } from '../../SigninContext';
import { useLocation, useNavigate } from 'react-router-dom';
import editIcon from '../../assets/images/edit.svg'
import editIconb from '../../assets/images/editb.svg'
import backArrow from '../../assets/images/backarrow.svg'

function HomePage({setHomeData, homeData, reload}) {
  const {setEditableSection, setFetched, fetched, editableSection, backendUrl} = useContext(SigninContext)
  const [fetchedData, setFetchedData] = useState(false)

  let location = useLocation()
  const path = location.pathname;

  let editPage = path.includes('edit') ? true : false

  const handleSectionClick = (section) => {
    setEditableSection(section)
    setFetched(false)
  }
  const navigate = useNavigate()
  const onBackClick = () => {
    navigate("/dashboard")
  }

  useEffect(() => {
    axios.get(`${backendUrl}homepage/`).then(res =>
      {
        setHomeData(res.data)
        setFetchedData(true)
        console.log("new Data", res.data)
      }
    )
  }, [reload])

  const NewArray = (section) =>  homeData.filter(function(value, index, arr){  
    return value.section === section;
  });


  return editPage && fetchedData && homeData.length > 0 ? (
    <div className='HomePage_Container'>
      <div>
        <div  className="backLink" onClick={() => onBackClick()}>
          <img alt="" src={backArrow}  />
          <p>Back To Dashboard</p>
        </div>
        <img alt="" src={editIcon}  className="editIcon" onClick={() => handleSectionClick("header")}/>
        <Header header={NewArray("header")[0]}/>
      </div>
      <div style={{border: editableSection === 'info' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("info")}/>
        <Info info={NewArray("info")[0]}/>
      </div>
      <div style={{border: editableSection === 'About Us' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("About Us")}/>
        <AboutUs aboutus={NewArray("About Us")[0]}/>
      </div>
      <div style={{border: editableSection === 'Dream' ? "5px solid red" : "none"}} >
        <img alt="" src={editIcon}  className="editIcon" onClick={() => handleSectionClick("Dream")}/>
        <Dream dream={NewArray("Dream")[0]}/>
      </div>
      <div style={{border: editableSection === 'Business Verticals' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("Business Verticals")}/>
        <RoadMap  buss={NewArray("Business Verticals")[0]}/>
      </div>
      <div style={{border: editableSection === 'Key Projects' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("Key Projects")}/>
        <OurProjects keyProjects={NewArray("Key Projects")[0]}/>
      </div>
    </div>
  ): 
  fetchedData && homeData.length > 0? 
  (
    <div className='HomePage_Container'>
      <Header header={NewArray("header")[0]} />
      <Info info={NewArray("info")[0]}/>
      <AboutUs aboutus={NewArray("About Us")[0]}/>
      <Dream dream={NewArray("Dream")[0]}/>
      <RoadMap buss={NewArray("Business Verticals")[0]} />
      <OurProjects keyProjects={NewArray("Key Projects")[0]}/>
    </div>
  )
  : <div className='spinner spinner2'/>
}

const mapDispatchToProps = (dispatch) => ({
  setHomeData: (array) => dispatch(setHomeData(array))
});
const mapStateToProps =  ({
  homeData: {homeData},
  reload: {reload}
})   => ({
  homeData,
  reload
})


export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
