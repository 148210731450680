import React, { useEffect, useState } from 'react';


import './Info.style.scss';
const Column = ({text1, text2}) => (
  <div className='Column_Container'>
    <p className='text1'>{text1}</p>
    <p className='text2'>{text2}</p>
  </div>
)


function Info( {info}) {
  const [stopSpinning, setStopSpinning] = useState(false)
  useEffect(() => {  
    const interval = setInterval(() => { 
      setStopSpinning(true)} , 1000); return () => clearInterval(interval);
    }, [info]
  );
  return (
    <div className='Info_Container' id='tokens'>
      <div className='row1'>
        {
          stopSpinning 
          ? Object.keys(info.text).map((keyName, i) => <Column key={i} text1={info.text[keyName].title} text2={info.text[keyName].text} />)
          : Object.keys(info.text).map((data, i) => <div className='spinner' key={i}/>)
        }  
      
      </div>
    </div>
  )
}

export default Info