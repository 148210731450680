import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { setReload } from '../../redux/pages/pages.actions';

import { SigninContext } from '../../SigninContext';
import SigninForm from '../../components/SigninForm/SigninForm'
import Upload from '../../assets/images/upload.png';

import './EditAboutUsPage.styles.scss';
import AboutusPage from '../AboutusPage/AboutusPage';
import AboutUsSection from './AboutUsSection';
import DropDown from '../AboutusPage/DropDown';

function EditAboutUsPage({signedIn, setReload, reload}) {

  const {editableSection, backendUrl}= useContext(SigninContext)


  const [uploadedImage, setUploadedImage] = useState([{name:null, file: null}])
  const [uploadedClientImage, setUploadedClientImage] = useState([])

  const [loading, setLoading] = useState(false)
  const [header, setHeader] = useState({})
  const [images, setImages] = useState([null])
  const [fetchedHeader, setFetchedHeader] = useState(false)

  const [ clients, setClients] = useState()

  const [fetchedAboutUs, setFetchedAboutUs] = useState(false)
  const [fetchedClass, setFetchedClass] = useState(false)
  const [fetchedMission, setFetchedMisson] = useState(false)
  const [fetchedClients, setFetchedClients] = useState(false)
  const [aboutUsText, setAboutUsText] = useState({})

  const [classData, setClassData] = useState({})

  const [bodyHtml, setBodyHtml] = useState()
  const [newClient, setNewClient] = useState({name:"", title: "", image: ""})
  const [uploadedImageClient,setUploadedImageClient] = useState(false)

  const [uploaded, setUploaded] = useState()

  useEffect(() => {
    if( editableSection.length > 0){
      axios.get(`${backendUrl}aboutuspageSections/${editableSection}`).then(res =>
      {
        if(editableSection === "Clients"){
            setClients(JSON.parse(res.data[0].text))
            setFetchedClients(true)
        } else if (editableSection === "header") {
          let text = res.data[0].text
          setHeader(JSON.parse(text))
          setFetchedHeader(true)
          console.log(res.data[0])
        } else if (editableSection === "About Us" || editableSection === "Vision") {
            let text = JSON.parse(res.data[0].text)
            setAboutUsText(text)
            setFetchedAboutUs(true) 
        } else if (editableSection === "Mission") {
            setAboutUsText(JSON.parse(res.data[0].text))
            setFetchedMisson(true)
        } else if (editableSection === "Classifications") {
            setClassData(JSON.parse(res.data[0].text))
            setFetchedClass(true)
        } 
      })
      
    }
  }, [editableSection, reload])


  const onHeaderChange = (e, keyName) => {
    setHeader(headerText => ({
      ...headerText,
      ...{[keyName] : e.target.value}
    }))
  }
  const onAboutUSTextChange = (e, keyName) => {
    setAboutUsText(headerText => ({
      ...headerText,
      ...{[keyName] : e.target.value}
    }))
  }
  const onClientChange = (e, keyName) => {
    setClients(clients => ({
      ...clients,
      ...{[keyName] : e.target.value}
    }))
  }

  const onClassChange = (e, arrayName, type) => {
          setClassData( classData => ({
          ...classData,
          [arrayName] : { ...classData[arrayName], ...{[type]: e.target.value}}
          }))
      }

  const onSaveClick = () => {
    setLoading(true)
    console.log("hi")
    if(editableSection === "header"){
      const formData = new FormData();
      formData.append("text", JSON.stringify(header));
      formData.append("section", "header");
      formData.append("pathname", "");
      uploadedImage.length > 0 && uploadedImage.map( image =>  {
          formData.append(image.name, image)
      })
        axios.put(`${backendUrl}updateAboutusSection`,formData
        ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
    if(editableSection === "About Us" || editableSection === "Vision"){
      let newText = {
        title: aboutUsText.title,
        text: bodyHtml,
        image: aboutUsText.image
      }
      console.log("new Text", newText)
      const formData = new FormData();
      formData.append("text", JSON.stringify(newText));
      formData.append("section", editableSection);
      formData.append("pathname", "");
      formData.append("images", JSON.stringify(images));
      formData.append(uploadedImage[0].name, uploadedImage[0])
      axios.put(`${backendUrl}updateAboutusSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
  
    if(editableSection === "Mission"){
      let newText = {
        title: aboutUsText.title,
        text: aboutUsText.text,
        image: aboutUsText.image
      }
      const formData = new FormData();
      formData.append("text", JSON.stringify(newText));
      formData.append("section", editableSection);
      formData.append("pathname", "");
      formData.append("images", JSON.stringify(images));
      formData.append(uploadedImage[0].name, uploadedImage[0])
      axios.put(`${backendUrl}updateAboutusSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
  
    if(editableSection === "Classifications"){
      
      const formData = new FormData();
      formData.append("text", JSON.stringify(classData));
      formData.append("section", "Classifications");
      formData.append("pathname", "");
      uploadedImage.length > 0 && uploadedImage.map( image =>  {
        formData.append(image.name, image)
      })
      axios.put(`${backendUrl}updateAboutusSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
  
    if(editableSection === "Clients"){
     
      const formData = new FormData();
      formData.append("text", JSON.stringify(clients));
      formData.append("section", "Clients");
      formData.append("pathname", "");
      uploadedClientImage.length > 0 && uploadedClientImage.map( image =>  {
        formData.append(image.name, image)
      })
      axios.put(`${backendUrl}updateAboutusSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
      setUploadedClientImage([])
      setFetchedClients(false)
      setImagesToUpload()
      setUploadedImageClient(false)
    } else {
      setLoading(false)
    }
    setTimeout(() => {
      setReload()
    }, [500])
    setFetchedClients(false)
    setUploadedImage([{name:null, file: null}])
  }
 
  const imageSrc2 = (name) => {
    return `${backendUrl}${name}`
  }

  const saveOneImage = (e) => {
    let files = e.target.files
    setUploadedImage([files[0]]);
    setImages({image: files[0].name})
    let event = {
        target: {
          value: files[0].name
        }
    }
    onAboutUSTextChange(event, "image")
  }
  const saveHeaderImage = (e) => {
    let files = e.target.files
    setUploadedImage([files[0]]);
    setImages({image: files[0].name})
    let event = {
        target: {
          value: files[0].name
        }
    }
    onHeaderChange(event, "image")
  }

  
  const classSaveImage = (event, keyName) => {
    let files = event.target.files
    let imgs = uploadedImage
    let array = files[0].name
    imgs.push(files[0]);
    setUploadedImage(imgs);
    let e ={
      target:{
        value: array
      }
    }
    onClassChange(e, keyName, "image")
  }
  
  const onClientImageDelete = (i, keyName ) => {
    let array = clients.clients[keyName].clients
    let NewArray = array.filter(function(value, index, arr){ 
      return index !== i;
    });
    setClients(
      clients => ({
          ...clients,
          clients:{
            ...clients.clients,
            [keyName]: {...clients.clients[keyName]  , clients: NewArray}
          }

      })
    )
  }

  const onNewClientChange = (e, type) => {
    setNewClient({...newClient, ... {[type]: e.target.value}})
  }

  const [imagesToUpload, setImagesToUpload] = useState([])

  const AddClient = () => {
    if(newClient.image === ""){
      return alert("Please upload an image and try again")
    }
    if(newClient.title === ""){
      return alert("Please select client's type and try again")
    }
    let newItem = newClient.name !== "" 
      ? {name: newClient.name, image: newClient.image}
      : {image: newClient.image}
    let array = clients.clients[newClient.title].clients
    array.push(newItem)
    setClients(
      clients => ({
          ...clients,
          clients:{
            ...clients.clients,
            [newClient.title]: {...clients.clients[newClient.title]  , clients: array}
          }

      })
    )
    setImagesToUpload([...imagesToUpload, uploadedClientImage[uploadedClientImage.length -1]])
    setNewClient({name:"", title: "", image: ""})
    setUploaded()
  }
  const uploadImage = (e) => {
    let files = e.target.files
    setUploadedClientImage([...uploadedClientImage, files[0]]);
    setNewClient({...newClient, ... {image: files[0].name}})
    setUploaded( URL.createObjectURL(e.target.files[0]))
    setUploadedImageClient(true)    

  }

  return loading ? <div className='spinner' style={{margin: "auto", marginTop: "45vh"}}/>
  : !signedIn ? <SigninForm /> :  (
    <div className='EditAboutUsPage_Container'>
      <div className='page'>
        <AboutusPage  />
      </div>
      <div className='EditingContainer'>
        { editableSection === 'header' && fetchedHeader &&
          <div className='EditHeader'>
            <h1>Edit Header Section</h1>
            <div className="InfoInputs">
                <div  className="subContainer_Info">
                  <div>
                    <h3>Title</h3>
                    <input type="text" value={header.title}   onChange={e => onHeaderChange(e, "title")}/>
                  </div>
                </div>
            </div>
            <img alt="" src={imageSrc2(header.image)} className="dreamImage"/>
            <div  className='dreamInputImage'>
              <img alt='' src={Upload} className='upload'/>
              <label>Upload Images</label>
              <input type="file" onChange={(e)=> saveHeaderImage(e)} className='inputfile'/>
            </div>
            <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
          </div>
        }
        { (editableSection === "About Us" || editableSection === "Vision") && fetchedAboutUs &&
            <AboutUsSection 
              editableSection={editableSection} 
              aboutUsText={aboutUsText} 
              setBodyHtml={setBodyHtml} 
              saveOneImage={saveOneImage}
              onSaveClick={onSaveClick} 
              imageSrc2={imageSrc2}
              onAboutUSTextChange={onAboutUSTextChange}
            /> 
        }
        { editableSection === 'Mission' && fetchedMission &&
          <div className='EditHeader aboutus'>
            <h1>Edit Mission Section</h1>   
            <div className="InfoInputs">
                <div  className="subContainer_Info">
                  <div>
                    <h3>Title</h3>
                    <input type="text" value={aboutUsText.title}   onChange={e => onAboutUSTextChange(e, "title")}/>
                  </div>
                  <div>
                    <h3>Text</h3>
                    <input type="text" value={aboutUsText.text}   onChange={e => onAboutUSTextChange(e, "text")}/>
                  </div>
                </div>
            </div>
            <img alt="" src={imageSrc2(aboutUsText.image)} className="dreamImage"/>

            <div  className='dreamInputImage'>
              <img alt='' src={Upload} className='upload'/>
              <label>Upload Image</label>
              <input type="file" onChange={(e)=> saveOneImage(e)} className='inputfile'/>
            </div>
            <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
          </div>
        }
        { editableSection === 'Classifications' && fetchedClass &&
          <div className='EditHeader aboutus'>
            <h1>Edit Classifications Section</h1>
            <div className="InfoInputs">
            {
                Object.keys(classData).map((keyName, i) => 
                <div key={i} className="subContainer_Info bussSub">
                  <h3>Title:</h3>
                  <input type="text" value={classData[keyName].title}   onChange={e => onClassChange(e, keyName, "title")}/>
                  <h3>Text:</h3>
                  <input type="text" value={classData[keyName].text}   onChange={e => onClassChange(e, keyName, "text")}/>
                  <h3>Image:</h3>
                  <img alt="" src={imageSrc2(classData[keyName].image)} className="bussImage"/>
                  <div  className='dreamInputImage'>
                    <img alt='' src={Upload} className='upload'/>
                    <label>Upload Image</label>
                    <input type="file" onChange={(e)=> classSaveImage(e, keyName)} className='inputfile'/>
                  </div>
                </div>
            )}
            </div>
            <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
          </div>
        }
        { editableSection === 'Clients' && fetchedClients &&
          <div className='EditHeader aboutus'>
            <h1>Edit Our Clients Section</h1>
            <div className="InfoInputs">
                <div  className="subContainer_Info">
                  <div>
                    <h3>Title</h3>
                    <input type="text" value={clients.title}   onChange={e => onClientChange(e, "title")}/>
                  </div>
                  <div>
                    <h3>Description</h3>
                    <input type="text" value={clients.description}   onChange={e => onClientChange(e, "description")}/>
                  </div>
                </div>
                {
                  Object.keys(clients.clients).map((keyName, i) => 
                    <div key={i}>
                      <DropDown show={true} type={clients.clients[keyName].title} images={clients.clients[keyName].clients} imageSrc={imageSrc2} onClientImageDelete={onClientImageDelete}   key={i} editPage={true} keyName={keyName}/>
                    </div>
                  )
                }
            </div>
            <div className='AddClient'>
              <h4>Add a client</h4>
              <label>Client name (optional)</label>
              <input type="text" value={newClient.name}   onChange={(e) => onNewClientChange(e, "name")}/>
              <label>Client Type (*)</label>
              <select onChange={(e) => onNewClientChange(e, "title")} value={newClient.title}>
                <option defaultValue  hidden>Please Select</option>
                {
                  Object.keys(clients.clients).map((keyName, i) => 
                    <option key={i} value={keyName}>{clients.clients[keyName].title}</option>
                  )
                }
              </select>
              <div className='imagesToUpload_Container'>

                { uploadedImageClient && imagesToUpload.map((item, i) =>
                    <img alt=""  src={URL.createObjectURL(item)} className="imagesToUpload" key={i}/> 
                  )
                }
              </div>
              <div  className='dreamInputImage'>
                <img alt='' src={Upload} className='upload'/>
                <label>Upload An Image*</label>
                <input type="file" onChange={(e)=> uploadImage(e)} className='inputfile'/>
                <img alt='' src={uploaded} className='uploaded'/>

              </div>
              <div className='flex'>
                <button onClick={() => AddClient()} className="btn-3 Green_Button"> Add Client </button>
                <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
  

const mapDispatchToProps = (dispatch) => ({
  setReload: () => dispatch(setReload())
});
const mapStateToProps =  ({
  signedIn: {signedIn},
  reload: {reload}

})   => ({
  signedIn,
  reload
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAboutUsPage)