import React, { useEffect, useState, useContext } from 'react';
import Carousel from 'nuka-carousel';
import axios from 'axios';


import { featuredImages } from '../../assets/data';
import { SigninContext } from '../../SigninContext';

import play from '../../assets/images/play.png';
import './Header.style.scss';
import { HashLink } from 'react-router-hash-link';

function Header({header}) {
  const {backendUrl}= useContext(SigninContext)
  const [headerText, setHeaderText] = useState({})

  useEffect(() => {
    setHeaderText(JSON.parse(header.text))
  }, [header])
   
  const imageSrc = (name, path) => {
    return `${backendUrl}featured/${name}`
  }

  return (
    <div className='Header_Container' id='header'>
      <div className='Carousel'>
        <Carousel 
          autoplay={true} 
          autoplayInterval="4000"
          wrapAround = {true} 
          withoutControls
          speed={1000}
          disableAnimation={true}
          animation='fade'
        >
          {
            header.images.items.length && header.images.items.map((image, i) =>
            <div 
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .8)),  url(${imageSrc(image)})`
              }} 
              key={i} 
              className="corousel_image"
            >
              <HashLink to='video' className='play_container'>
                <img alt="play" src={play} className="play_img" />
              </HashLink>
              <div className='Description'> 
                <p className='text1'>{headerText["text1"]} </p>
                <h1>{ headerText.text2}</h1>
                <p className='text2'>{headerText.text3} </p>
              </div>
              
            </div>
            )
          }
        </Carousel>
      </div>
      <div className='row'>
        <div className='pentagon' />
      </div>
 
    </div>
  )
}


export default  Header
