import React, { useEffect, useState, useContext, useCallback } from 'react';
import { connect } from 'react-redux';

import { SigninContext } from '../../SigninContext';
import axios from 'axios';

import './EditProjectsPage.styles.scss';
import EditProject from '../../components/EditProject/EditProject';
import SigninForm from '../../components/SigninForm/SigninForm';
import { HashLink } from 'react-router-hash-link';

function EditProjectsPage({signedIn}) {

  const {backendUrl }= useContext(SigninContext)

  const [projects, setProjects] = useState([])
  const [index, setIndex] = useState(0)
  const [updating, setUpdating] = useState(false)
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showAddProject, setShowAddProject] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [projectFilter, setProjectFilter] = useState("All Projects")
  const [titleFilter, setTitleFilter] = useState("")
 
  useEffect(() => {
    if(!fetchedProjects)
    setProjects([])
    axios.get(`${backendUrl}projects`).then(res =>
      {
        let sorted = res.data.sort((a, b) => a.id - b.id)
        setProjects(sorted)
        setFetchedProjects(true)
      }
    )
  }, [updating, showAddProject, showEdit, showTable, fetchedProjects])


  const imageSrc = (name, path, type) => {
    let slicedPath = path.slice(0, -1)
    let symbol = type[0]
    
    let newPath = slicedPath + symbol

    return `${backendUrl}${newPath}/${name}`
  }

  const setRenderedIndex = (i) => {
    setIndex(i)
    if(!showEdit){
      setShowEdit(true)
    }
    if(showAddProject){
      setShowAddProject(false)
    }
  }

  const onProjectDelete = (id) => {
    axios.put(`${backendUrl}deleteProject`, {id: id}).then(res =>
      console.log(res)
    ).catch(err => console.log(err))
    setUpdating(!updating);
    if(showEdit){
      setShowEdit(false)
    }
    if(showAddProject){
      setShowAddProject(false)
    }
    if(!showTable){
      setShowTable(true)
    }
    setFetchedProjects(false)
  }

  const onEditClick = (i) =>  {
    setRenderedIndex(i)
    setShowTable(false)
  }
  const onCancelEditClick = (i) =>  {
    setShowEdit(false)
    setShowAddProject(false)

    setShowTable(true)
  }

  const handleTypeChange = (e) => {
    setProjectFilter(e.target.value)
  }
  const handleTitleChange = (e) => {
    setTitleFilter(e.target.value)
  }
  const onAddProjectClick = () => {
    setShowTable(false)
    setShowAddProject(true)
  }
  const ProjectTable = useCallback(() => {
    return fetchedProjects && (
      <div className='Project_Table'>
        {
          projects.length && projects.map((project, i) =>{
            const {title, id, keyproject, type} = project 
            return  (type === projectFilter ||  projectFilter === "All Projects") && (title.toLowerCase().includes(titleFilter.toLowerCase())) ?(
              <div key={id} className="Project_Row" >
                <p className='title'> {title}</p>
                {
                  keyproject === 'True' && <p className='keyproject'>Key Project</p>
                }
                <div>
                  <button onClick={() => onProjectDelete(id)} className="row_button btn-2 Delete_Button">Delete</button>
                  <button onClick={() => onEditClick(i)} className="row_button  btn-3 Green_Button">Edit</button>
                </div>
              </div>
            ):null
          }) 
        }
      </div>
    )
  }, [updating, projects, projects.length, fetchedProjects, titleFilter])

  return !signedIn ? (
    <div className='EditProjectsPage_Container'>
      <SigninForm />
    </div>
  ) : (
    <div className='EditProjectsPage_Container'>
      { showTable &&
        <div >
          <h1>Edit Projects
          <HashLink to='/dashboard' className='btn-3 Green_Button' > Back To Dashboard</HashLink>

          </h1>
          <label>Filter by type:</label>
          <select value={projectFilter} onChange={handleTypeChange}  >
            <option value="All Projects">All Projects</option>
            <option value="Roads">Roads Projects</option>
            <option value="Buildings">Buildings Projects</option>
            <option value="Infrastructure">Infrastructure</option>
            <option value="Marine">Marine</option>
            <option value="Lakes">Lakes</option>
            <option value="Operation and Maintenance">Operation and Maintenance</option>
          </select>
          <label>Title Search:</label>
          <input type="text" value={titleFilter} onChange={handleTitleChange}/>
          <button className='btn-1 Tertiary_Button' onClick={() => onAddProjectClick()}>Add A Project</button>
          <ProjectTable />
        </div>
      }
      {projects.length && (showEdit || showAddProject) && 
        <div className=''>
          <EditProject 
            project={!showAddProject ?  projects[index] : null}
            ids={!showAddProject ? projects[index].id : null} 
            imageSrc={imageSrc} 
            setUpdating={setUpdating} 
            updating={updating} 
            length={projects.length}
            showAddProject={showAddProject}
            showEdit={showEdit}
            setShowEdit={setShowEdit}
            index={index}
            onProjectDelete={onProjectDelete}
            onCancelEditClick={onCancelEditClick}
            setShowAddProject={setShowAddProject}
          />
        </div>
      }
    </div>
)
}

const mapStateToProps =  ({
  signedIn: {signedIn}
})   => ({
  signedIn
})

export default connect(mapStateToProps)(EditProjectsPage)