import React, { useState, useEffect } from 'react';


export default function VisionSection({imageSrc, vision}) {

    const [data, setData] = useState({})
    useEffect(() => {
      setData(JSON.parse(vision.text))
    }, [vision])

  
  return(
    <div className='row1 reverse' id='vision'  data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-once={true} data-aos-delay={400}>
        <img alt='' src={imageSrc(data.image)} className='img' />
        <div className='description'>
            <h2>{data.title}</h2>
            <div dangerouslySetInnerHTML={{__html: data.text}} />
        </div>
    </div>
  )
}


