
export const setHomeData = (array) => ({
  type: "SETHOMEDATA",
  payload: array
});

export const setAboutusData = (array) => ({
  type: "SETABOUTUSDATA",
  payload: array
});

export const setReload = () => ({
  type: "RELOAD"
});



