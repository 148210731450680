import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { setReload } from '../../redux/pages/pages.actions';


import { SigninContext } from '../../SigninContext';
import SigninForm from '../../components/SigninForm/SigninForm'
import './EditHomePage.styles.scss';
import { HashLink } from 'react-router-hash-link';
import HomePage from '../HomePage/HomePage';
import deleteIcon from '../../assets/images/deleteMini.svg'
import TipEditor from '../../components/TipEditor/TipEditor';
import Upload from '../../assets/images/upload.png';


function EditHomePage({signedIn, setReload, reload}) {
  const { editableSection, backendUrl}= useContext(SigninContext)

  const [info, setInfo] = useState({})
  const [uploadedImage, setUploadedImage] = useState([{name:null, file: null}])

  const [header, setHeader] = useState({})
  const [images, setImages] = useState([null])
  const [fetchedHeader, setFetchedHeader] = useState(false)
  const [headerText, setHeaderText] = useState([])

  const [fetchedAboutUs, setFetchedAboutUs] = useState(false)
  const [fetchedInfo, setFetchedInfo] = useState(false)
  const [fetchedDream, setFetchedDream] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fetchedBusiness, setFetchedBusiness] = useState(false)
  const [fetchedKey, setFetchedKey] = useState(false)
  const [aboutUsText, setAboutUsText] = useState({})

  const [bussText, setBussText] = useState([])

  const [bodyHtml, setBodyHtml] = useState()

  useEffect(() => {
    if(editableSection.length > 0){
      axios.get(`${backendUrl}homepageSections/${editableSection}`).then(res =>
        {
          if(editableSection === "info"){
            setInfo(res.data[0].text)
            setFetchedInfo(true)
          } else if (editableSection === "header") {
            setHeader(res.data[0])
            console.log("header data", res.data[0])
            let text = res.data[0].text
            setHeaderText(JSON.parse(text))
            setImages(res.data[0].images)
            setFetchedHeader(true)
          } else if (editableSection === "About Us") {
            let text = res.data[0].text
            setAboutUsText(JSON.parse(text))
            setImages(res.data[0].images.image)
            setFetchedAboutUs(true)
          } else if (editableSection === "Dream") {
            setImages(res.data[0].images.image)
            setAboutUsText(JSON.parse(res.data[0].text))
            setFetchedDream(true)
          } else if (editableSection === "Business Verticals") {
            setAboutUsText(res.data[0].text)
            let newText = JSON.parse(res.data[0].text)
            setBussText(newText)
            setFetchedBusiness(true)
          } else if (editableSection === "Key Projects") {
            setAboutUsText(JSON.parse(res.data[0].text))
            setFetchedKey(true)
          }
        }
      )
    }
  }, [reload, editableSection])


  const onInfoTitleChange = (e, keyName) => {
    setInfo( info => ({
      ...info,
      [keyName]: {
        text: info[keyName].text,
        title: e.target.value
      }
    }))
  }
  const onInfoTextChange = (e, keyName) => {
    setInfo( info => ({
      ...info,
      [keyName]: {
        text: e.target.value,
        title: info[keyName].title
      }
    }))
  }
  const onHeaderTextChange = (e, keyName) => {
    setHeaderText(headerText => ({
      ...headerText,
      ...{[keyName] : e.target.value}
    }))
  }
  const onAboutUSTextChange = (e, keyName) => {
    setAboutUsText(headerText => ({
      ...headerText,
      ...{[keyName] : e.target.value}
    }))
  }


  const onBusinessChange = (e, index, arrayName) => {
    setBussText( arrays => [
       ...arrays.slice(0, index), {...arrays[index], [arrayName]: e.target.value }, ...arrays.slice(index + 1)
    ])
  }

  const CheckAvailabilty = obj => {
    if (typeof obj != null) {
      return true;
    } else return false;
  };
  const onSaveClick = () => {
    setLoading(true)
    if(editableSection === "info"){
      axios.put(`${backendUrl}updateHomeSection`,{
        section: "info",
        text: info
      }
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
    if(editableSection === "header"){
      const formData = new FormData();
      formData.append("text", JSON.stringify(headerText));
      formData.append("section", "header");
      formData.append("pathname", "featured");
      formData.append("images", JSON.stringify(images));
      uploadedImage.length > 0 && uploadedImage.map( image =>  {
          formData.append(image.name, image)
      })
      axios.put(`${backendUrl}updateHomeSection`, formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
    if(editableSection === "About Us"){
      let newText = {
        title: aboutUsText.title,
        text: bodyHtml,
        button: aboutUsText.button

      }
      let imageObject = {
        image: images
      }
      console.log("images", images)
      const formData = new FormData();
      formData.append("text", JSON.stringify(newText));
      formData.append("section", "About Us");
      formData.append("pathname", "");
      formData.append("images", JSON.stringify(imageObject));
      formData.append(uploadedImage[0].name, uploadedImage[0])
      axios.put(`${backendUrl}updateHomeSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})

    } else
  
    if(editableSection === "Dream"){
      let newText = {
        title: aboutUsText.title,
        text: aboutUsText.text
      }
      let imageObject = {
        image: images
      }
      const formData = new FormData();
      formData.append("text", JSON.stringify(newText));
      formData.append("section", "Dream");
      formData.append("pathname", "");
      images !== "undefined" && formData.append("images", JSON.stringify(imageObject));
      uploadedImage.length > 0 && formData.append(uploadedImage[0].name, uploadedImage[0])
      axios.put(`${backendUrl}updateHomeSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})

    } else
    if(editableSection === "Business Verticals"){
     
      const formData = new FormData();
      formData.append("text", JSON.stringify(bussText));
      formData.append("section", "Business Verticals");
      formData.append("pathname", "");
      uploadedImage.length > 0 && uploadedImage.map( image =>  {
        formData.append(image.name, image)
      })
      console.log("Uploaded", uploadedImage)
      axios.put(`${backendUrl}updateHomeSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})
    } else
  
    if(editableSection === "Key Projects"){
     
      const formData = new FormData();
      let newText = {
        title: aboutUsText.title,
        text: bodyHtml,
      }
      formData.append("text", JSON.stringify(newText));
      formData.append("section", "Key Projects");
      formData.append("pathname", "");
      axios.put(`${backendUrl}updateHomeSection`,formData
      ).then(res =>{ console.log(res); setLoading(false)}).catch(err => {console.log(err); setLoading(false)})

    } else{
      setLoading(false)
    }
    setTimeout(() => {
      setReload()
    }, [700])
  
    setUploadedImage([{name:null, file: null}])
  }


  const imageSrc = (name, path) => {
    return `${backendUrl}${path}/${name}`
  }
  const imageSrc2 = (name) => {
    return `${backendUrl}${name}`
  }
  const RemoveImage = (i) => {
    let NewArray = header.images.items.filter(function(value, index, arr){ 
      return value !== header.images.items[i];
    });
    let newobject = {
      items: NewArray
    }
    axios.put(`${backendUrl}updateHomeSection`,{
      images: JSON.stringify(newobject),
      deletedImage: header.images.items[i],
      pathname: "featured"
    }
    ).then(res =>{
        console.log(res)
    }).catch(err => console.log(err))
    setHeader( header => ({
      ...header,
      images :{
        ...header.images,
        ...{items: NewArray}
      }
    }))
    setTimeout(() => {
      setReload()
      setFetchedHeader(false)
    }, [500])
  }
  const saveOneImage = (e) => {
    let files = e.target.files
    setUploadedImage([files[0]]);
    setImages({image: files[0].name})
  }
  const bussImageSave = (event, i) => {
    let files = event.target.files
    let imgs = uploadedImage
    let array = files[0].name
    imgs.push(files[0]);
    setUploadedImage(imgs);
    let e ={
      target:{
        value: array
      }
    }
    onBusinessChange(e, i, "image")
  }
  
  return loading ? <div className='spinner' style={{margin: "auto", marginTop: "45vh"}}/>
  : !signedIn ? <SigninForm /> :  (
    <div className='EditHomePage_Container'>
      <div className='page'>
        <HomePage />
      </div>
        <div className='EditingContainer'>
          { editableSection === 'info' && fetchedInfo &&
            <div className='EditInfo'>
              <h1>Edit Info Section</h1>
              <div className="InfoInputs">
                { Object.keys(info).map((keyName, i) => 
                  <div key={i} className="subContainer_Info">
                      <input type="text" value={info[keyName].title} onChange={e => onInfoTitleChange(e, keyName)}/>
                      <textarea type="text" value={info[keyName].text} onChange={e => onInfoTextChange(e, keyName)}/>
                  </div>
                )}
                <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
              </div>
            </div>
          }
          { editableSection === 'header' && fetchedHeader &&
            <div className='EditHeader'>
              <h1>Edit Header Section</h1>
              <div className="InfoInputs">
                {  fetchedHeader && Object.keys(headerText).map((keyName, i) => 
                  keyName !== "video" && <div key={i} className="subContainer_Info">
                    <textarea type="text" value={headerText[keyName]}   onChange={e => onHeaderTextChange(e, keyName)}/>
                  </div>
                )}
              </div>
              <div className='images_container'>
                <label>Featured Images</label>
                <div className="images"> 
                    {CheckAvailabilty(images.items)  && Array.isArray(images.items) && images.items.map((image, i) => {
                      return<div  
                        key={i}
                        className="image" 
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)) , url(${imageSrc(image, "featured")})`, 
                            backgroundSize: "cover",
                        }} 
                      >
                          <img alt="" src={deleteIcon} onClick={() => RemoveImage(i)}/>
                      </div>}
                    )}
                </div>
              </div>
             <div  className='dreamInputImage'>
                <img alt='' src={Upload} className='upload'/>
                <label>Upload Images</label>
                <input type="file" onChange={(e)=> saveOneImage(e)} className='inputfile'/>
              </div>
              <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
            </div>
          }
          { editableSection === 'About Us' && fetchedAboutUs &&
            <div className='EditHeader aboutus'>
              <h1>Edit About Us Section</h1>
              <div className="InfoInputs">
                  <div  className="subContainer_Info">
                    <div>
                      <h3>Title</h3>
                      <input type="text" value={aboutUsText.title}   onChange={e => onAboutUSTextChange(e, "title")}/>
                    </div>
                    <div>
                      <h3>Body</h3>
                      <TipEditor body={aboutUsText.text} setBodyHtml={setBodyHtml}/>
                    </div>
                    <div>
                      <h3>Button</h3>
                      <input type="text" value={aboutUsText.button}   onChange={e => onAboutUSTextChange(e, "button")}/>
                    </div>
                  </div>
              </div>
              <h3 className='h3'>Image:</h3>
              <img alt="" src={imageSrc2(images)} className="dreamImage"/>
              <div  className='dreamInputImage'>
                <img alt='' src={Upload} className='upload'/>
                <label>Upload Image</label>
                <input type="file" onChange={(e)=> saveOneImage(e)} className='inputfile'/>
              </div>
              <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
            </div>
          }
          { editableSection === 'Dream' && fetchedDream &&
            <div className='EditHeader aboutus'>
              <h1>Edit Dream Section</h1>
              <div className="InfoInputs">
                  <div  className="subContainer_Info">
                    <div>
                      <h3>Title</h3>
                      <input type="text" value={aboutUsText.title}   onChange={e => onAboutUSTextChange(e, "title")}/>
                    </div>
                    <div>
                      <h3>Button</h3>
                      <input type="text" value={aboutUsText.text}   onChange={e => onAboutUSTextChange(e, "text")}/>
                    </div>
                  </div>
              </div>
              <img alt="" src={imageSrc2(images)} className="dreamImage"/>

              <div  className='dreamInputImage'>
                <img alt='' src={Upload} className='upload'/>
                <label>Upload Image</label>
                <input type="file" onChange={(e)=> saveOneImage(e)} className='inputfile'/>
              </div>
              <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
            </div>
          }
          { editableSection === 'Business Verticals' && fetchedBusiness &&
            <div className='EditHeader aboutus'>
              <h1>Edit Business Verticals Section</h1>
              <div className="InfoInputs">
              {
                bussText.length > 0 &&   bussText.map((item, i) => 
                  <div key={i} className="subContainer_Info bussSub">
                    <h3>Text:</h3>
                    <input type="text" value={item.text}   onChange={e => onBusinessChange(e, i, "text")}/>
                    <h3>Image:</h3>
                    <img alt="" src={imageSrc2(item.image)} className="bussImage"/>
                    <div  className='dreamInputImage'>
                      <img alt='' src={Upload} className='upload'/>
                      <label>Upload Image</label>
                      <input type="file" onChange={(e)=> bussImageSave(e, i)} className='inputfile'/>
                    </div>
                  </div>
              )}
              </div>
              <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
            </div>
          }
          { editableSection === 'Key Projects' && fetchedKey &&
            <div className='EditHeader aboutus'>
              <h1>Edit Key Projects Section</h1>
              <div className="InfoInputs">
                  <div  className="subContainer_Info">
                    <div>
                      <h3>Title</h3>
                      <input type="text" value={aboutUsText.title}   onChange={e => onAboutUSTextChange(e, "title")}/>
                    </div>
                    <div>
                      <h3>Text</h3>
                      <TipEditor body={aboutUsText.text} setBodyHtml={setBodyHtml}/>
                    </div>
                  </div>
              </div>
              <div style={{maxWidth :"90%"}}>
                <p><strong>Note: </strong>To change key projects go to <HashLink to="/editProjects">Edit Projects Page</HashLink>, select the project you would like to add or remove as a key projects and change the value of key project (true or false) </p>
              </div>
              <button onClick={() => onSaveClick()} className="btn-3 Green_Button"> Save </button>
            </div>
          }
        </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setReload: () => dispatch(setReload())
});
const mapStateToProps =  ({
  signedIn: {signedIn},
  reload: {reload}

})   => ({
  signedIn,
  reload
})

export default connect(mapStateToProps, mapDispatchToProps)(EditHomePage)
