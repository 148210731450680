import React, { useEffect, useState, useContext } from 'react';
import 'aos/dist/aos.css';
import Aos from 'aos';
import { HashLink } from 'react-router-hash-link';

import './ProjectCard.style.scss';
import { SigninContext } from '../../SigninContext';

import building from '../../assets/images/building.jpg';
import infrastructure from '../../assets/images/infrastructure.jpg';
import road from '../../assets/images/road.jpg';
import marine from '../../assets/images/marine.jpg';
import lake from '../../assets/images/lake.jpg';
import maintenance from '../../assets/images/maintenance.jpg';


function ProjectCard({images, title, id, date, type, pathname}) {
    console.log("imagess", images)
    useEffect(()=>{
        Aos.init({ 
            duration: 500,
            disable: 'mobile'
        })
    }, [])
    const {backendUrl}= useContext(SigninContext)

    const width = window.innerWidth
    useEffect(() => {
        if(!showText && window.innerWidth <821){
            setShowText(true)
        }
    }, [width])
    const [ showText, setShowText] = useState(false)
    const Show = () => {
        if(!showText){
            setShowText(true)
        }
    }
    const Hide = () => {
        if(showText && width > 820){
            setShowText(false)
        }
    }
    let imageArray = images && images.length > 0
        ? [images[0]]
        : type === 'Buildings' 
        ? building
        : type === 'Infrastructure' 
        ? infrastructure
        : type === 'Roads'
        ? road
        : type === 'Marine'
        ? marine
        : type === 'Lakes'
        ? lake
        : maintenance

     let Title = title.slice(0, 160)

     const imageSrc = (name, path, type) => {
        let slicedPath = path.slice(0, -1)
        let symbol = type[0]
        
        let newPath = slicedPath + symbol
  
        return `${backendUrl}${newPath}/${name}`
      }

    let background = images && images.length > 0 ?
    `url(${imageSrc(images[0], pathname, type)})`
  
     :`url(${imageArray})`
  
    return  (
    <div 
        className='ProjectCard_Container' 
        style={ 
            {
                backgroundImage: background

            }
        } 
        onMouseEnter={Show}
        onMouseLeave={Hide}
    >
        {
            showText  && 
            <div
            style={ 
                showText 
                ? {
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9))`,
                    width: "90%",
                    height: "90%",
                } 
                : null
            }  
            >
                <h4 
                 data-aos={ width > 821 && "slide-down" }
                 data-aos-easing="ease-in-sine" 
                 data-aos-once={true}
                >
                    {Title}
                </h4>
                <HashLink 
                 data-aos={ width > 821 && "slide-up" }
                 data-aos-easing="ease-in-sine" 
                 data-aos-mirror={true}
                 data-aos-delay="300" 
                 to={`/projects/${id}`}  
                 className='Tertiary_Button btn-1'
                >
                    View Project 
                </HashLink>
            </div>
        }
    </div>
  )
}

export default ProjectCard