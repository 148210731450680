import React, { useLayoutEffect, useState, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { SigninContext } from './SigninContext';
import HomePage from './pages/HomePage/HomePage';
import './App.style.scss';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import AllProjects from './pages/AllProjects/AllProjects';
import Navbar from './components/Navbar/Navbar';
import AboutusPage from './pages/AboutusPage/AboutusPage';
import Footer from './components/Footer/Footer';
import VideoPlayer from './components/VideoPlayer/VideoPlayer';
import ContactUs from './pages/ContactUs/ContactUs';
import NotFound from "./pages/NotFound/NotFound";
import BusinessVertical from './pages/BusinessVertical/BusinessVertical';
import EditProjectsPage from './pages/EditProjectsPage/EditProjectsPage';
import Dashboard from './pages/Dashboard/Dashboard';
import EditHomePage from './pages/EditHomePage/EditHomePage';
import EditAboutUsPage from './pages/EditAboutUsPage/EditAboutUsPage';


const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {
  const [editableSection, setEditableSection] = useState("")
  const [fetched, setFetched] = useState(false)
  const backendUrl = "https://desolate-beach-41964.herokuapp.com/"

  const providerValue = useMemo(() => ({ 
    editableSection,
    setEditableSection,
    fetched,
    setFetched,
    backendUrl
  }), [
    editableSection,
    setEditableSection,
    fetched,
    setFetched,
    backendUrl
  ])

  return (
    <div className="App">
      <Navbar />
      <Wrapper>
        <SigninContext.Provider value={providerValue}>
          <Routes>
            <Route path="" element={<HomePage />} />
            <Route path="video" element={<VideoPlayer />} />
            <Route path="BusinessVerticals" element={<BusinessVertical />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="projects" element={<AllProjects />} />
            <Route path="Aboutus" element={<AboutusPage />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="editProjects" element={<EditProjectsPage />} />
            <Route path="editHomePage" element={<EditHomePage />} />
            <Route path="editAboutusPage" element={<EditAboutUsPage />} />
            <Route path="projects/:projectid" element={<ProjectPage />} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </SigninContext.Provider>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default App;
