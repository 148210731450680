import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import deleteIcon from '../../assets/images/deleteMini.svg'
import Aos from 'aos';

const DropDown = ({ type, backendUrl, images, editPage, keyName, onClientImageDelete}) => {
    const [show, setShow] = useState(false)
    const handleClick = () => {
        setShow(!show)
    }
    useEffect(()=>{
        Aos.init({ duration: 400 })
    }, [show])

    const imageSrc = (name) => {
        return `${backendUrl}${name}`
      }
    
    return  (
        <div className={editPage ? 'DropDown_Contianer2' : 'DropDown_Contianer'}>
            <h3 
             onClick={handleClick}
            >
                {type} {
                    show 
                    ? <span className='slowRotate2'>{">"}</span> 
                    : <span className='slowRotate1'> {">"} </span>
                }
            </h3>
            { show &&
                <div 
                    className='clients'   
                    data-aos="fade-right" 
                    data-aos-easing="ease-in-sine"     
                >
                    { 
                        editPage ?

                        images && images.map((image, i) => 
                            <div key={i}>
                                <div  
                                    className="images" 
                                    data-aos-once={true} 
                                    key={i}
                                    style={{
                                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)) , url(${imageSrc(image.image)})`, 
                                        backgroundSize: "cover",
                                    }} 
                                >
                                    <img alt="" src={deleteIcon} className="delete" onClick={() => onClientImageDelete(i, keyName)} />
                                </div>
                                <p>{image.text}</p>
                            </div>
                        )
                        : images && images.map((image, i) => 
                        <div key={i}>
                            <img  
                                alt=""
                                className="images" 
                                data-aos-once={true} 
                                key={i}
                                src={imageSrc(image.image)}
                            />
                            <p>{image.text}</p>
                        </div>
                       
                        )
                        
                    }
                </div>
            }
        </div>
    );
}
export default DropDown
