import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Dream from '../../assets/images/dream.webp'
import { HashLink } from 'react-router-hash-link';

export default function HeaderSection({backendUrl, header}) {

  const [data, setData] = useState({})
  useEffect(() => {
    setData(JSON.parse(header.text))
  }, [header])
  const imageSrc = (name) => {
    return `${backendUrl}${name}`
  }
  
  return (
    <div 
      className='header' 
      style={{
          backgroundImage: `linear-gradient(rgba(41, 105, 167, .3),rgba(41, 105, 167, .5)) , url(${imageSrc(data.image)})`, 
      }}
    >
      <div className='link'>
      <HashLink to="/">
          Home {">"} 
      </HashLink>
      <p>{data.title}</p>
      </div>
      <h1>{data.title}</h1>
    </div>
  )
}


