import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './Services.styles.scss';


const ServiceCard = ({title, image, text, backendUrl}) => {

    const imageSrc = (name) => {
        return `${backendUrl}${name}`
    }
    return(
        <div className='ServiceCard_Container'>
            <img alt="" src={imageSrc(image)} />
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    )
}

function Services({backendUrl, classif}) {

    const [data, setData] = useState({})

    useEffect(() => {
        setData(JSON.parse(classif.text))
    }, [classif])
  return (
    <div className='Services_Container'  id='classifications'>
        <h2>Classifications</h2>
        <div className='row2'>
            {
                Object.keys(data).map((keyName, i) => 
                    <ServiceCard image={data[keyName].image} title={data[keyName].title} text={data[keyName].text} backendUrl={backendUrl} key={i} />
                )
            }
        </div>
        
    </div>
  )
}

export default Services