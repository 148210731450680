import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import 'aos/dist/aos.css';
import Aos from 'aos';

import { setAboutusData } from '../../redux/pages/pages.actions';
import './AboutusPage.styles.scss';
import Services from '../../components/Services/Services';
import { SigninContext } from '../../SigninContext';
import { useLocation, useNavigate } from 'react-router-dom';
import editIcon from '../../assets/images/edit.svg'
import editIconb from '../../assets/images/editb.svg'
import backArrow from '../../assets/images/backarrow.svg'

import AboutusSection from './AboutusSection';
import VisionSection from './VisionSection';
import MissionSection from './MissionSection';
import ClientsSection from './ClientsSection';
import HeaderSection from './HeaderSection';

function AboutusPage({setAboutusData, aboutusData, reload}) {
  const [fetchedData, setFetchedData] = useState(false)
  const {setEditableSection, setFetched, fetched, editableSection, backendUrl} = useContext(SigninContext)

  const imageSrc = (name) => {
    return `${backendUrl}${name}`
  }
  
  useEffect(() => {
    axios.get(`${backendUrl}aboutuspage/`).then(res =>
      {
          console.log("usss", res.data)
          setAboutusData(res.data)
          setFetchedData(true)

      }
    )
  }, [reload])
    
  let location = useLocation()
  const path = location.pathname;

  let editPage = path.includes('edit') ? true : false

  const handleSectionClick = (section) => {
    setEditableSection(section)
    setFetched(false)
  }
  const NewArray = (section) =>  aboutusData.filter(function(value, index, arr){ 
    return value.section === section;
  }); 
  useEffect(()=> {
   
    Aos.init({ duration: 500 })
  }, [])
 

  const navigate = useNavigate()
  const onBackClick = () => {
    navigate("/dashboard")
  }
  return editPage && fetchedData ? (
    <div className='AboutusPage_Container' >
      <div  className="backLink" onClick={() => onBackClick()}>
          <img alt="" src={backArrow}   />
          <p>Back To Dashboard</p>
      </div>
      <div style={{border: editableSection === 'header' ? "5px solid red" : "none"}} >
        <img alt="" src={editIcon}  className="editIcon" onClick={() => handleSectionClick("header")}/>
        <HeaderSection fetched={fetched}  backendUrl={backendUrl} header={NewArray("header")[0]} />
      </div>
      <div style={{border: editableSection === 'About Us' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("About Us")}/>
        <AboutusSection fetched={fetched} imageSrc={imageSrc} backendUrl={backendUrl} aboutus={NewArray("About Us")[0]}/>
      </div>
      <div style={{border: editableSection === 'Vision' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("Vision")}/>
        <VisionSection fetched={fetched} imageSrc={imageSrc}  backendUrl={backendUrl} vision={NewArray("Vision")[0]}/>
      </div>
      <div style={{border: editableSection === 'Mission' ? "5px solid red" : "none"}} >
        <img alt="" src={editIcon}  className="editIcon" onClick={() => handleSectionClick("Mission")}/>
        <MissionSection fetched={fetched} imageSrc={imageSrc}  backendUrl={backendUrl}  mission={NewArray("Mission")[0]}/>
      </div>
      <div style={{border: editableSection === 'Classifications' ? "5px solid red" : "none"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("Classifications")}/>
        <Services fetched={fetched}  backendUrl={backendUrl} classif={NewArray("Classifications")[0]}/>
      </div>
      <div style={{border: editableSection === 'Clients' ? "5px solid red" : "none", height: "100%"}} >
        <img alt="" src={editIconb}  className="editIcon" onClick={() => handleSectionClick("Clients")}/>
        <ClientsSection  fetched={fetched}  backendUrl={backendUrl} clients={NewArray("Clients")[0]}/>
      </div>
    </div>
  )
  : fetchedData ? (
    <div className='AboutusPage_Container' >
      <HeaderSection backendUrl={backendUrl} header={NewArray("header")[0]}/>
      <AboutusSection fetched={fetched} imageSrc={imageSrc} backendUrl={backendUrl} aboutus={NewArray("About Us")[0]}/>
      <VisionSection fetched={fetched} imageSrc={imageSrc} backendUrl={backendUrl} vision={NewArray("Vision")[0]}/>
      <MissionSection fetched={fetched} imageSrc={imageSrc}  backendUrl={backendUrl} mission={NewArray("Mission")[0]}/>
      <Services fetched={fetched} backendUrl={backendUrl} classif={NewArray("Classifications")[0]}/>
      <ClientsSection  fetched={fetched} backendUrl={backendUrl} clients={NewArray("Clients")[0]}/>
      <div className='curve' />
    </div>
  )
  :<div className='spinner spinner2' />
}

const mapDispatchToProps = (dispatch) => ({
    setAboutusData: (array) => dispatch(setAboutusData(array))
  });
const mapStateToProps =  ({
  aboutusData: {aboutusData},
  reload: {reload},
})   => ({
  aboutusData,
  reload
})


export default connect(mapStateToProps, mapDispatchToProps)(AboutusPage)
  