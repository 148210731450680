import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import PageReducer from './pages/pages.reducer';


const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['signedIn']
};

const rootReducer = combineReducers({
  signedIn: userReducer,
  homeData: PageReducer,
  aboutusData: PageReducer,
  reload: PageReducer
  
});

export default persistReducer(persistConfig, rootReducer);
