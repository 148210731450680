import React, { useEffect, useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';


import './Dream.styles.scss'
import { SigninContext } from '../../SigninContext';

function Dream({dream}) {
  
  const {backendUrl}= useContext(SigninContext)
  const [fetchedDream, setFetchedDream] = useState(false)
  const [image, setImage] = useState()
  const [dreamText, setDreamText] = useState({})

  const imageSrc = (name) => {
    return `${backendUrl}${name}`
  }
  useEffect(() => {
    setDreamText(JSON.parse(dream.text))
    setImage(dream.images.image)
    setFetchedDream(true)
  }, [dream])

  return fetchedDream &&(
    <div 
      className='Dream_Container' 
      style={{
        backgroundImage: `linear-gradient(rgba(41, 105, 167, .3),rgba(41, 105, 167, .5)) , url(${imageSrc(image)})`, 
          backgroundAttachment: "fixed",
        backgroundSize: "cover"
      }}
    >
        <h2>{dreamText.title}</h2>
        <HashLink to='aboutus' className='Primary_Button btn-0'>
          {dreamText.text}
        </HashLink>
    </div>
  )
}

export default Dream