import React from 'react';
import { connect } from 'react-redux';


import SigninForm from '../../components/SigninForm/SigninForm'
import './Dashboard.styles.scss';
import { HashLink } from 'react-router-hash-link';
import { signOut } from '../../redux/user/user.actions'



function Dashboard({signedIn, signOut}) {

  return !signedIn ? <SigninForm /> :  (
    <div className='Dashboard_Container'>
      <div className='dashboard_Menu'>
        <HashLink to='/editProjects'> Edit Projects </HashLink>
        <HashLink to='/editHomePage'> Edit Home Page </HashLink>
        <HashLink to='/editAboutusPage'> Edit About Us Page </HashLink>
      </div>
      <div className='c2'>
        <h1>Welcome to the dashboard</h1>
        <p>Please click one of the links on the left to procceed or <a onClick={() => signOut()}>click here</a> to sign out</p>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});
const mapStateToProps =  ({
  signedIn: {signedIn}
})   => ({
  signedIn
})


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
